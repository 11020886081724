import * as Comlink from 'comlink'

window.addEventListener('DOMContentLoaded', () => {
  websdkready()
})

function websdkready () {
  ZoomMtg.preLoadWasm()
  ZoomMtg.prepareWebSDK()
  console.log('[UXT] Web SDK ready and waiting for event.')
}

function init (leaveUrl, webEndpoint) {
  console.log('[UXT] running init')
  return new Promise((resolve, reject) => {
    ZoomMtg.init({
      leaveUrl,
      webEndpoint,
      disableCORP: !window.crossOriginIsolated, // default true
      disablePreview: true,
      debug: false,
      audioPanelAlwaysOpen: true,
      disableRecord: true,
      disableInvite: true,
      meetingInfo: [],
      showMeetingHeader: false,
      disableReport: true,
      screenShare: true,
      success: () => resolve(true),
      error: (err) => reject({ zoomSdkError: true, ...err }),
    })
  })
}

function join (meetingConfig) {
  return new Promise((resolve, reject) => {
    const joinPayload = {
      meetingNumber: meetingConfig.meetingNumber,
      passWord: String(meetingConfig.password),
      sdkKey: meetingConfig.sdkKey,
      signature: meetingConfig.signature,
      userName: meetingConfig.userName,
    }

    if (meetingConfig.userEmail) {
      joinPayload.userEmail = meetingConfig.userEmail
    }

    if (meetingConfig.tk) {
      joinPayload.tk = meetingConfig.tk
    }

    if (meetingConfig.zak) {
      joinPayload.zak = meetingConfig.zak
    }

    if (meetingConfig.customerKey) {
      joinPayload.customerKey = meetingConfig.customerKey
    }

    ZoomMtg.join({
      ...joinPayload,
      success: () => resolve(true),
      error: (err) => reject({ zoomSdkError: true, ...err }),
    })
  })
}

// ---------------------------------------------------------------------------------------------------------------------

async function initAndJoin (meetingConfig, serviceListener = null) {
  await init(meetingConfig.leaveUrl, meetingConfig.webEndpoint)
  await join(meetingConfig)
  await customizeUi()
  const observer = suppressLiveStreamModal()

  if (serviceListener) {
    for (const type of ['onUserJoin', 'onUserLeave', 'onUserUpdate']) {
      const cb = type === 'onUserUpdate' ?
        debounce(data => serviceListener(type, data), 400)
        : data => serviceListener(type, data)
      ZoomMtg.inMeetingServiceListener(type, cb)
    }
  }

  /** API to control the Zoom player will become available only if the initialization and subsequent join is successful */
  return Comlink.proxy({
    claimHostWithHostKey,
    reclaimHost,
    makeHost,
    makeCoHost,
    getCurrentUser,
    getAttendeesList,
    leaveMeeting,
    rename,
    sendChat,
    [Comlink.finalizer]: () => {
      observer.disconnect()
    },
  })
}

Comlink.expose(initAndJoin, Comlink.windowEndpoint(self.parent), process.env.ALLOWED_ORIGIN.split(','))

// ---------------------------------------------------------------------------------------------------------------------

async function customizeUi () {
  await wait(500)

  /** Close "Join audio by computer popup - cannot disable it programmatically" */
  const closeAudioBtn = document.querySelector('.zmu-tabs__tab-container--top button.zm-btn.join-dialog__close')
  if (closeAudioBtn) {
    closeAudioBtn.click()
  }

  const meetingInfoContainer = document.querySelector('.meeting-info-container')
  if (meetingInfoContainer) {
    meetingInfoContainer.remove()
  }

  /** Remove zoom close button since we need to use our custom */
  const leaveBtnContainer = document.querySelector('.footer__leave-btn-container')
  if (leaveBtnContainer) {
    leaveBtnContainer.remove()
  }

  /**
   * The following buttons are disabled only via CSS, because removing them from DOM will break ZOOm react in some cases
   * - .footer__btns-container div[feature-type="record"]
   * - .footer__btns-container div[feature-type="reaction"]
   * - .footer__btns-container div[feature-type="more"]
   */

  document.body.classList.remove('uxtweak-zoom-loading')
}

/** Watches for "live stream" modal being opened and closes it immediately */
function suppressLiveStreamModal () {
  const cb = () => {
    /* New MIUI modal */
    const modalText = document.querySelector('#notificationManager #zoom2modal .disclaimer-content .disclaimer-content__title[title*="This meeting is being livestreamed."]')
    if (modalText && modalText.parentElement && modalText.parentElement.nextSibling && modalText.parentElement.nextSibling.childNodes[0]) {
      modalText.parentElement.nextSibling.childNodes[0].click()
    }

    /* Legacy modal - kept for back compatibility (remove after a year) */
    /** @legacy */
    const modal = document.querySelector('.ReactModalPortal .ReactModal__Content[aria-label*="livestream"]')
    if (modal) {
      const closeBtn = modal.querySelector('.zm-modal-footer-default-actions .zm-btn--primary')
      if (closeBtn) {
        closeBtn.click()
      }
    }
  }

  const observer = new MutationObserver(cb)
  observer.observe(document.body, { childList: true, subtree: false, attributes: false })
  /* Call for the first time, cause modal is probably already rendered. */
  cb()
  return observer
}

function reclaimHost () {
  return new Promise((resolve, reject) => {
    ZoomMtg.reclaimHost({
      error: err => reject({ zoomSdkError: true, ...err }),
      success: () => resolve(true),
    })
  })
}

/**
 * Turns the current user into the meeting host.
 * If the operation is successful, `true` is returned.
 * If not, `Error` instance is thrown.
 * Custom function is needed because `ZoomMtg.claimHostWithHostKey` does  not work.
 * @param {string} hostKey
 * @return {Promise<boolean>}
 */
async function claimHostWithHostKey (hostKey) {
  /** Check if the current user already is a host and if so, don't do anything */
  const me = await getCurrentUser()
  if (me.isHost) {
    return null
  }

  disableMeetingControls()
  let errorMessage = null

  try {
    const participantButton = document.querySelector('.footer__btns-container > div[feature-type="participants"] button')
    participantButton.click()

    await wait()

    const claimHostButton = document.querySelector('.participants-section-container__participants-footer-bottom button:first-child')

    /** Check if the button we found has a 'host' substring in it */
    if (!claimHostButton.innerText.toLowerCase().includes('host')) {
      participantButton.click()
      throw new Error('Claim host trigger not found!')
    }

    claimHostButton.click()
    participantButton.click()

    await wait()

    const input = document.querySelector('.dialog-claimhost-container .zm-modal-body-content input')
    triggerReactInputChange(input, hostKey)

    const submitButton = document.querySelector('.dialog-claimhost-container .zm-modal-footer .zm-btn--primary')
    submitButton.click()

    await wait(1500)
  } catch (err) {
    errorMessage = err.message
  }

  enableMeetingControls()
  if (errorMessage) {
    throw new Error(errorMessage)
  }

  return true
}

function makeHost (userId) {
  return new Promise((resolve, reject) => {
    ZoomMtg.makeHost({
      userId,
      error: err => reject({ zoomSdkError: true, ...err }),
      success: () => resolve(true),
    })
  })
}

function makeCoHost (userId) {
  return new Promise((resolve, reject) => {
    ZoomMtg.makeCoHost({
      userId,
      error: err => reject({ zoomSdkError: true, ...err }),
      success: () => resolve(true),
    })
  })
}

function getCurrentUser () {
  return new Promise((resolve, reject) => {
    ZoomMtg.getCurrentUser({
      error: err => reject({ zoomSdkError: true, ...err }),
      success: resp => resolve(resp.result.currentUser),
    })
  })
}

function getAttendeesList () {
  return new Promise((resolve, reject) => {
    ZoomMtg.getAttendeeslist({
      error: err => reject({ zoomSdkError: true, ...err }),
      success: resp => resolve(resp.result.attendeesList),
    })
  })
}

function leaveMeeting () {
  return new Promise((resolve, reject) => {
    ZoomMtg.leaveMeeting({
      error: err => reject({ zoomSdkError: true, ...err }),
      success: () => resolve(true),
    })
  })
}

function rename (userId, oldName, newName) {
  return new Promise((resolve, reject) => {
    ZoomMtg.rename({
      newName,
      oldName,
      userId,
      error: err => reject({ zoomSdkError: true, ...err }),
      success: () => resolve(true),
    })
  })
}

/**
 * Custom method for sending chat messages to other users. This API has no built-in one.
 * @return {Promise<boolean>}
 */
async function sendChat (zoomUserId, message) {
  let chatWasClosed = false

  try {
    /** Get the list of all users and find the target one by his zoom user ID */
    const users = await getAttendeesList()
    const targetUser = users.find(user => user.userId === zoomUserId)
    if (!targetUser) {
      throw new Error('Target user not found!')
    }

    /** Save his old name and create a temporary "unique" name so the user can be "reliably" identified */
    const oldName = targetUser.userName
    let identifier = oldName

    /** Check if there is at least one other user with the same name as our target user */
    const renamingNeeded = users.some(user => user.userId !== targetUser.userId && user.userName === targetUser.userName)
    /** If such user is found, the target user must be renamed, so he has a unique name */
    if (renamingNeeded) {
      identifier = `${oldName} (sending message...)`
      await rename({ userId: targetUser.userId, oldName, newName: identifier })
    }

    let chatReceiverMenuElement = document.getElementById('chatReceiverMenu')

    const zoomChatBtn = document.querySelector('.footer-chat-button button')
    if (!chatReceiverMenuElement) {
      disableMeetingControls()
      chatWasClosed = true
      /** Simulate clicking "Chat" button */
      zoomChatBtn.click()

      await wait(50)
      chatReceiverMenuElement = document.getElementById('chatReceiverMenu')
    }

    /* Open only if it's not already opened. */
    if (!chatReceiverMenuElement.classList.contains('show')) {
      /* This may seem odd, but due great work from the Zoom team, it's necessary. Good job. */
      const parent = document.defaultView.parent
      document.defaultView.parent = null
      chatReceiverMenuElement.firstChild.click()
      await wait(0)
      document.defaultView.parent = parent
      await wait(0)
    }

    /** A list of all users will appear, so we query it */
    const userOptionList = [...chatReceiverMenuElement.querySelectorAll('.chat-receiver-list__label')]
    /** Get the element that belongs to our user. Get it using the generated identifier from before. */
    const targetUserOption = userOptionList.find(elm => elm.textContent.trim() === identifier)
    /** Simulate clicking the element that corresponds to our user. This will set the chat to direct message mode. */
    targetUserOption.click()
    await wait(0)

    /** Query the textarea, populate it with the message, simulate React change event and simulate Enter press */
    const proseMirrorElement = document.querySelector('.chat-rtf-box__editor-outer .ProseMirror')
    const dataTransfer= new DataTransfer()
    dataTransfer.setData('text/plain', message)
    proseMirrorElement.dispatchEvent(new ClipboardEvent('paste', {
      bubbles: true,
      cancelable: true,
      clipboardData: dataTransfer
    }))
    await wait(0)
    document.querySelector('.chat-rtf-box__send').click()

    /** If the user was renamed, we rename him back, but need to wait for 5s, since the method has a rate limit */
    if (renamingNeeded) {
      wait(5000).then(() => rename({ oldName: identifier, newName: oldName, userId: zoomUserId }))
    }

    /** Close the chat */
    if (chatWasClosed) {
      zoomChatBtn.click()
    }

    await wait(1000)
    return true
  } finally {
    if (chatWasClosed) {
      enableMeetingControls()
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------

function wait (timeout = 100) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}

function disableMeetingControls () {
  document.body.classList.add('uxt-disabled')
}

function enableMeetingControls () {
  document.body.classList.remove('uxt-disabled')
}

/**
 * @see https://stackoverflow.com/questions/39065010/why-react-event-handler-is-not-called-on-dispatchevent
 * @see https://github.com/vitalyq/react-trigger-change
 */
function triggerReactInputChange (node, inputValue) {
  const descriptor = Object.getOwnPropertyDescriptor(node, 'value')

  node.value = `${inputValue}#`
  if (descriptor && descriptor.configurable) {
    delete node.value
  }
  node.value = inputValue

  // TODO: Replace deprecated API with the current one
  const e = document.createEvent('HTMLEvents')
  e.initEvent('change', true, false)
  node.dispatchEvent(e)

  if (descriptor) {
    Object.defineProperty(node, 'value', descriptor)
  }
}

/**
 * @see https://github.com/quasarframework/quasar/blob/c8c852b6a67f0bea14abc2080ac0e6bd21ebb5a0/ui/src/utils/debounce.js#L4
 */
function debounce (fn, wait = 250, immediate) {
  let timer = null

  function debounced (/* ...args */) {
    const args = arguments

    const later = () => {
      timer = null
      if (immediate !== true) {
        fn.apply(this, args)
      }
    }

    if (timer !== null) {
      clearTimeout(timer)
    } else if (immediate === true) {
      fn.apply(this, args)
    }

    timer = setTimeout(later, wait)
  }

  debounced.cancel = () => {
    timer !== null && clearTimeout(timer)
  }

  return debounced
}
